import logo from './img/logo.png';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="">
        <img src={logo} className="" alt="logo" />
        <p>
          Stay tuned for a completely new way to look at your beverage supply chain!        </p>

      </header>
    </div>
  );
}

export default App;
